<template>
	<div class="main-contents">
		<!-- 로그인 폼 -->
		<div class="contents">
			<div class="contents_body">
				<div class="tit">
					<span>로그인</span><br/>
					하이프로에 오신 것을 환영합니다.
				</div>
				<div class="loginbox">
					<div class="loginform">
						<div class="inputlist"><span>*</span> 아이디<InputComp v-model="id" @keyup.enter="login()"/></div>
						<div class="inputlist"><span>*</span> 비밀번호<InputComp type="password" v-model="password" @keyup.enter="login()"/></div>
						<SelectComp tagName="div" class="inputlist" type="yn" title="로그인 상태 유지" v-model="keepLoginYn"/>
						<div class="login-btn" @click="login">로그인</div>
						<div class="loginsmenu">
							<a @click="pageMove('MEM002M06')">아이디 찾기</a><span>ㅣ</span><a @click="pageMove('MEM002M02')">비밀번호 찾기</a><span>ㅣ</span><a @click="pageMove('MEM003M01')" >회원가입</a>
						</div>
					</div>
				</div>
			</div><!-- //contents_body -->
		</div><!-- //contents -->
	</div><!-- //main-contents -->
</template>

<script>
export default {
	data() {
		return {
			id: "",
			password: "",
			keepLoginYn: '',

			mberAsgRegInfo: {},
		};
	}, 	


	created() {
    document.title = "하이프로 로그인"
  	},


	methods: {
		login() {
			if(!this.id) {
				alert("아이디를 입력하지 않았습니다.");
				return;
			}

			if(!this.password) {
				alert("비밀번호를 입력하지 않았습니다.");
				return;
			}

			// this.$.login(this.id, this.password, this.keepLoginYn)
			// 	.then((res) => {
			// 		//console.log("getUserInfo success", res.data);
			// 		var viewNm = 'MYP101M01';
			// 		if(res.data.mberDivCd == '21') viewNm = 'MYP201M01';
			// 		else if(res.data.mberDivCd == '22') viewNm = 'MYP301M01';
			// 		else if(res.data.mberDivCd == '31') viewNm = 'MYP401M01';

			// 		if(res.data.mberDivCd == '11' && res.data.prfCnt == 0) {
			// 			this.$.popup('/mem/MEM001P01')
			// 				.then(res => {
			// 					viewNm = res.viewNm;
			// 					location.href = "/" + viewNm;
			// 				});
			// 		}else if(res.data.mberDivCd == '11' && res.data.ncsCnt == 0) {
			// 			this.$.popup('/tec/prf/PRF101P02')
            //                 .then(res => {
            //                     if(res) viewNm = 'PRF102M02';
                                
			// 					location.href = "/" + viewNm;
            //                 });
			// 		}else if(res.data.mberDivCd.substr(0, 1) == '0'){
			// 			location.href = "/admin";
			// 		}else {
			// 			location.href = "/" + viewNm;
			// 		}
			// 	})
			// 	.catch(this.$.httpErrorCommon);


			this.$.login(this.id, this.password, this.keepLoginYn)
			.then((res) => {
				//console.log("getUserInfo success", res.data);
				var viewNm = 'MYP101M01';
				if(res.data.mberDivCd == '21') viewNm = 'MYP201M01';
				else if(res.data.mberDivCd == '22') viewNm = 'MYP301M01';
				else if(res.data.mberDivCd == '31') viewNm = 'MYP401M01';

				// 기술인재 직원구분코드 조회
				if(res.data.mberDivCd == '11'){

					this.$.httpPost('/api/mem/prf/getMberAsgRegInfo', {mberSeq : this.$store.state.userInfo.mberSeq})
					.then(mberRes => {
						this.mberAsgRegInfo = mberRes.data.mberAsgRegInfo;

						// 이력서 미작성 || 소속사 미등록
						if(res.data.prfCnt == 0 ||this.mberAsgRegInfo.mberAsgRegYn == 'N') {
							// this.$.popup('/tec/prf/PRF101P04')
							this.$.popup('/mem/MEM001P01', {prfCnt : res.data.prfCnt, mberAsgRegYn : this.mberAsgRegInfo.mberAsgRegYn})
								.then(res => {
									viewNm = res.viewNm;
									location.href = "/" + viewNm;
								});
						}else if(res.data.ncsCnt == 0) {
							this.$.popup('/tec/prf/PRF101P02')
								.then(res => {
									if(res) viewNm = 'PRF102M02';
									
									location.href = "/" + viewNm;
								});
						}else {
						location.href = "/" + viewNm;
						}

					})
					.catch(err => {
						alert(err.response.data.error_description);
					});

				}else if(res.data.mberDivCd.substr(0, 1) == '0'){
					location.href = "/admin";
				}else {
					location.href = "/" + viewNm;
				}

				
			})
			.catch(this.$.httpErrorCommon);
		},

		pageMove(name) {
			this.$router.push(name);
		}
	},
};
</script>
